<template>
  <view class="page">
    <div class="s1">
      <div class="wrap" :class="(showAt && !contentData.isKeyword)?'at':''">
        <textarea v-model="text" class="inp" maxlength="500" placeholder="请输入" />
        <div class="p1 flex h-between">
          <div>
            <div v-if="(showAt && !contentData.isKeyword)" class="at" @click="showAt=!showAt;">
              <span>@新人昵称</span>
              <div class="del" />
            </div>
          </div>
          <div>{{ text.length }}/500</div>
        </div>
      </div>
    </div>

    <div class="p-footer h-end">
      <div class="flex h-between flex-1">
        <div>
          <label v-if="!showAt && !contentData.isKeyword" class="flex">
            <input v-model="showAt" type="checkbox" class="radio mr-1">
            <div>@新人昵称{{contentData.isKeyword}}</div>
          </label>
        </div>
        <div class="flex">
          <button class="btn auto gray" @click="submit(0)">取消</button>
          <button class="btn auto fill ml-3" @click="submit(1)">保存</button>
        </div>
      </div>
    </div>
  </view>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      text: '',
      showAt: false
    }
  },
  computed: {
    ...mapGetters(['globalData', 'contentData'])
  },
  mounted() {
    const pick = this.globalData
    if (pick.msgType === 2001) {
      this.text = pick.msgContent || ''
      this.showAt = pick.showAt || true
    } else {
      this.showAt = true
    }
  },
  methods: {
    submit(n) {
      if (n) {
        this.$store.commit('setGlobalData', {
          text: this.text,
          showAt: this.showAt && !this.contentData.isKeyword
        })
      }
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
	@import "index";
</style>
